import React from 'react';
import parse from 'html-react-parser';

import Row from '../Container/row';
import Col from '../Container/column';

import loadComponents from '../Loadable';
import loadLibrary from '../Loadable/library';

const Img = loadLibrary('gatsby-image');

const Link = loadComponents('link');
const Button = loadComponents('button');
// const ButtonInput = loadComponents('button-input');
const H2 = loadComponents('h2');
const Placeholder = loadComponents('blog-placeholder');
const PostMeta = loadComponents('blog-meta');

export default class Blog extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      items: [],
      // visible: 6,
    };
    // this.loadMore = this.loadMore.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.initBlogs();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  initBlogs = () => {
    const { data } = this.props;
    const allBlogs = data.edges;
    const blogs = [];
    allBlogs.forEach(blog => {
      blogs.push(blog.node);
    });
    if (this._isMounted) {
      this.setState({ items: blogs });
    }
  };

  // loadMore() {
  //   if (this._isMounted) {
  //     this.setState(prev => {
  //       return { visible: prev.visible + 6 };
  //     });
  //   }
  // }

  render() {
    const { items /* , visible */ } = this.state;
    return (
      <section>
        {items /* .slice(0, visible) */
          .map((item, i) => {
            const featuredImage =
              item.images.featuredImage && item.images.featuredImage.localFile;
            return (
              <div
                className="singlePost"
                key={item.title}
                style={i !== 0 ? singleStyles : {}}
              >
                <Row justify="space-between">
                  <Col className="image" lg={{ width: '20%' }}>
                    <Link to={item.link}>
                      {featuredImage ? (
                        <Img
                          image={featuredImage.childImageSharp.gatsbyImageData}
                          alt={item.title}
                          itemProp="image"
                        />
                      ) : (
                        <Placeholder title={item.title} />
                      )}
                    </Link>
                  </Col>
                  <Col className="content" lg={{ width: '77%' }}>
                    <Link to={item.link}>
                      <H2 line={1} margin="30px">
                        {item.title}
                      </H2>
                    </Link>
                    <PostMeta
                      date={item.date}
                      categories={item.categories}
                      margin="30px"
                    />
                    <div style={{ marginBottom: `1.875rem` }}>
                      {parse(item.excerpt)}
                    </div>
                    <Button
                      to={item.link}
                      className="button"
                      style={{ justifyContent: `center`, maxWidth: `300px` }}
                      primary
                    >
                      Read More
                    </Button>
                  </Col>
                </Row>
              </div>
            );
          })}
        {/* {visible < items.length && (
          <div className="loadBtn">
            <ButtonInput
              value="Load More News"
              secondary
              onClick={this.loadMore}
            />
          </div>
        )} */}
      </section>
    );
  }
}

const singleStyles = {
  marginTop: `3.75rem`,
  paddingTop: `3.75rem`,
  borderTop: `1px dotted rgba(0,0,0,0.2)`,
};
